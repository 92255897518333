<script>
import {loadPageContent} from "~/services/parameters.js";

export default {
  name: "VatInput",
  emits: ['input', 'verify'],
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      numberOfRequest: 0,
      edit: '',
      vat: null
    }
  },
  watch: {
    modelValue: {
      handler (val, oldVal) {
        if (!val) {
          this.edit = ''
        } else {
          this.edit = val + ''
        }
        if (val !== oldVal) {
          if (val && val.length > 2) {
            this.verifyVat(val)
          }
        }
      }
    }
  },
  methods: {
    verifyVat (val) {
      this.numberOfRequest++
      loadPageContent("/api/verify-vat-id/" + val)
          .then(response => {
            this.vat = response.data.vat;
            this.$emit('verify', JSON.parse(JSON.stringify(this.vat)))
          })
          .catch(e => {
            console.error(e);
          })
          .finally(() => {
            this.numberOfRequest--
          })
      ;
    },
    updateModelValue (val) {
      console.log(val)
      this.$emit('input', val)
      // if (val.length) {
      //   this.verifyVat(val)
      // }
    }
  },
  computed: {
    isValid () {
      return this.vat && this.vat.valid
    }
  }
}
</script>

<template>
  <el-input v-model="edit" @input="updateModelValue($event)" :placeholder="placeholder" maxlength="14">
    <template #suffix>
      <span style="min-width: 20px;">
        <el-icon v-if="numberOfRequest > 0" class="is-loading"><loading/></el-icon>
        <el-icon v-else-if="this.isValid"><CircleCheck /></el-icon>

      </span>
    </template>
  </el-input>
</template>

<style scoped>

</style>