<script>
import VatInput from "~/modules/user/components/VatInput.vue";

export default {
  name: "CompanyEdit",
  emits: ['update:modelValue', 'changeField'],
  components: {
    VatInput
  },
  props: {
    prefixErrorKey: {
      type: String,
      default: ''
    },
    formErrors: {
      type: Object,
      default () {
        return {}
      }
    },
    modelValue: {
      type: Object,
      // required: true
    },
    fields: {
      type: [Array, String],
      default () {
        return 'all'
      }
    }
  },
  data () {
    return {
      edit: {
        siret: '',
        raisonSociale: '',
        capital: '',
        vatNumber: '',
        hasVat: false
      }
    }
  },
  watch: {
    modelValue: {
      handler (val, oldVal) {
        if (val) {
          this.edit = {...JSON.parse(JSON.stringify(this.edit)), ...JSON.parse(JSON.stringify(val))};
        }
      },
      immediate: true,
      deep: true
    },
    // edit: {
    //   handler (val, oldVal) {
    //     console.log('val')
    //     console.log(val)
    //     console.log('oldVal')
    //     console.log(oldVal)
    //     console.log(this.modelValue)
    //     console.log(Object.keys(oldVal))
    //     if (oldVal !== null && Object.keys(old).length && !objectEquals(val, this.modelValue)) {
    //       console.log('change')
    //       this.$emit('update:modelValue', val)
    //     }
    //   },
    //   deep: true
    // }
  },
  methods: {
    errorKey (field) {
      let key = this.prefixErrorKey.length ? this.prefixErrorKey + '.' + field : field
      return this.formErrors[key]
    },
    updateModelValue (field, val) {
      this.edit[field] = val
      this.$emit('update:modelValue', JSON.parse(JSON.stringify(this.edit)))
      let key = field;
      if (this.prefixErrorKey.length) {
        key = this.prefixErrorKey + '.' + field
      }
      this.$emit('changeField', key, val)
    },
    hasField (field) {
      if (this.fields === 'all') {
        return true
      }
      return this.fields.includes(field)
    },
  },
  computed: {
    hasVat: {
      set(val) {
        this.updateModelValue('hasVat', val ? 1 : 0)
      },
      get() {
        return Boolean(this.edit.hasVat)
      }
    }
  }
}
</script>

<template>
  <div class="company-form-section">
    <div class="form-input-2-cols">
      <el-form-item v-if="hasField('raisonSociale')" label="Raison sociale" :error="errorKey('raisonSociale')" prop="raisonSociale">
        <el-input v-model="edit.raisonSociale" placeholder="Raison sociale" @input="updateModelValue('raisonSociale', $event)"></el-input>
      </el-form-item>
      <el-form-item v-if="hasField('siret')" :label="$t('signup.company.label-registration-number')" :error="errorKey('siret')" prop="raisonSociale">
        <el-input v-model="edit.siret" :placeholder="$t('signup.company.placeholder-registration-number')" @input="updateModelValue('siret', $event)"></el-input>
      </el-form-item>
    </div>
    <el-checkbox v-model="hasVat" :label="$t('signup.company.label-has-vat')"></el-checkbox>
    <div class="form-input-2-cols">
      <el-form-item v-if="hasField('capital')" :error="errorKey('capital')"  label="Capital" prop="raisonSociale">
        <el-input v-model="edit.capital" placeholder="1000" @input="updateModelValue('capital', $event)">
          <template #append>€</template>
        </el-input>
      </el-form-item>

    </div>
    <div v-if="hasVat" class="form-input-2-cols">
      <el-form-item v-if="hasField('vatNumber')" :label="$t('signup.company.label-vat-number')" prop="raisonSociale" :error="errorKey('vatNumber')">
        <vat-input v-model="edit.vatNumber" :placeholder="$t('signup.company.placeholder-vat-number')"  @input="updateModelValue('vatNumber', $event)"></vat-input>
      </el-form-item>
      <el-form-item v-if="hasField('vat') && edit.taxCountry" label="Taux TVA" prop="raisonSociale">
        <el-input v-model="edit.taxCountry.vat" disabled placeholder="1000">
          <template #append>%</template>
        </el-input>
      </el-form-item>
    </div>
  </div>
</template>

<style scoped>

</style>