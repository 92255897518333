<script>
export default {
  emits: ['close'],
  name: "ModalLayout",
  props: {
    header: {
      type: Boolean,
      default: true
    },
    show: {
      type: Boolean,
      default: false
    },
    footer: {
      type: Boolean,
      default: false
    },
    modalClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  }
}
</script>

<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask" @click="$emit('close')">
      <div class="modal-container" @click.stop :class="modalClass">
        <div class="lightyshare-modal-header">
          <slot name="header" v-if="header"><h3>default header</h3></slot>
        </div>

        <div class="lightyshare-modal-body">
          <slot name="body">default body</slot>
        </div>

        <div v-if="footer" class="lightyshare-modal-footer">
          <slot name="footer">
            default footer
            <button
                class="modal-default-button"
                @click="$emit('close')"
            >OK</button>
          </slot>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style lang="scss">
  .modal-mask {
    padding: 10px;
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //background: rgba(255, 0, 0, 0.5);
    //backdrop-filter: blur(8px);
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    transition: opacity 0.3s ease, transform 0.3s ease;
  }

  .modal-container {
    width: 300px;
    margin: auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
  }

  .lightyshare-modal-header h3 {
    font-weight: bold;
    font-size: 18px;
    color: #080025;
  }

  .lightyshare-modal-body {
    margin: 20px 0;
  }

  .modal-default-button {
    float: right;
  }

  /*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

  .modal-enter-from {
    opacity: 0;
    .modal-container {
    }
  }

  .modal-leave-to {
    opacity: 0;
    .modal-container {
    }
  }

  .modal-enter-from .modal-container,
  .modal-leave-to .modal-container {
    //transform: translateY(0);
    transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
</style>